var $ = require('jquery');
require('popper.js');
require('bootstrap');
import Toastify from 'toastify-js';

$(window).on('load', function() {

	// ----------------------------------------- Autoresize textareas
	$('textarea').each(function () {
		this.setAttribute('style', 'height:' + (this.scrollHeight) + 'px;overflow-y:hidden;');
	}).on('input', function () {
		this.style.height = 'auto';
		this.style.height = (this.scrollHeight) + 'px';
	});

	//------------------------------------------ Notifications
	window.showErrorToast = showErrorToast;
	window.showMessageToast = showMessageToast;

	function showErrorToast(text) {
		if ($('.toast')[0]) {
			return
		} else
			Toastify({
				text: text,
				duration: 5000,
				gravity: "bottom",
				position: 'center', // `left`, `center` or `right`
				backgroundColor: "#aa0000",
				className: "toast",
				stopOnFocus: true, // Prevents dismissing of toast on hover
			}).showToast();
	}

	function showMessageToast(text) {
		if ($('.toast')[0]) {
			return
		} else
			Toastify({
				text: text,
				duration: 3000,
				gravity: "bottom",
				position: 'center', // `left`, `center` or `right`
				backgroundColor: "#28a745",
				className: "toast",
				stopOnFocus: true, // Prevents dismissing of toast on hover
			}).showToast();
	}
	// ---------------------------------------- Contact Form

	var $contactForm = $('#contact-form');
	if ($contactForm.length) {
		console.log("form seen")
		$contactForm.on("submit", function (e) {
			e.preventDefault();
			const $form = $(this);

			$form.addClass('was-validated');
			if (!$form[0].checkValidity()) {
				return
			}

			const vals = $contactForm.serializeArray();
			const method = $form.attr('method');
			let url = $form.attr('action');

			const req = $.ajax({
				url: url,
				data: vals,
				method: method,
			});

			req.done(function (result) {
				showMessageToast("Email sent");
			});

			req.fail(function (result) {
				showErrorToast("Could not send email at this time");
			});
		});



	}


});
